import { Inject, Injectable } from '@angular/core';
import { Environment } from '../models/environment.model';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { CritereReferentiel } from '../models/critere-referentiel.model';
import { Observable, of, tap } from 'rxjs';
import { EnumTypeCritere } from '../enums/enum.typeCritere';
import { IntervenantsDc } from '../models/intervenantsDC.model';
import { ReferentielDocumentsAap } from '../models/referentielDocumentsAAP.model';
import { DepensesProjetConfig } from '../models/depenses-projet-config.model';
import { map } from 'rxjs/operators';
import { Produit } from '../models/produit.model';

@Injectable({
  providedIn: 'root',
})
export class ReferentielHttpService {
  depensesProjetConfig: DepensesProjetConfig | null = null;

  constructor(@Inject('environment') private environment: Environment, public httpClient: HttpClient) {}

  saveReferentiel(
    referentiel: CritereReferentiel,
    isModification: boolean,
    idReferentiel: string
  ): Observable<HttpResponse<CritereReferentiel>> {
    if (isModification) return this.saveUpdateReferentiel(referentiel, idReferentiel);

    return this.saveCritere(referentiel);
  }

  saveReferentielDocument(
    document: ReferentielDocumentsAap,
    isModification: boolean,
    idReferentiel: string
  ): Observable<HttpResponse<ReferentielDocumentsAap>> {
    if (isModification) return this.saveUpdateReferentielDocument(document, idReferentiel);

    return this.saveCreatereferentielDocument(document);
  }

  saveIntervenant(intervenant: IntervenantsDc): Observable<HttpResponse<IntervenantsDc>> {
    return this.saveCreateIntervenant(intervenant);
  }

  saveProduit(produit: Produit): Observable<HttpResponse<Produit>> {
    return this.saveCreateProduit(produit);
  }

  updateProduit(produit: Produit, idProduit: string): Observable<HttpResponse<Produit>> {
    produit.id = idProduit;
    return this.httpClient.put<Produit>(`${this.environment.apiAdminUrl}ref/produits/${idProduit}`, JSON.stringify(produit), {
      observe: 'response',
    });
  }

  saveReferentielDepensesProjets(depenses: any): Observable<HttpResponse<DepensesProjetConfig>> {
    return this.saveCreateDepensesProjets(depenses);
  }

  getCriteresEvaluationsByType(typeCritere: EnumTypeCritere): Observable<HttpResponse<CritereReferentiel[]>> {
    return this.httpClient.get<CritereReferentiel[]>(`${this.environment.apiAdminUrl}criteres`, {
      params: {
        type: typeCritere?.toString().toUpperCase(),
      },
      observe: 'response',
    });
  }

  getIntervenatsDC(): Observable<HttpResponse<IntervenantsDc[]>> {
    return this.httpClient.get<IntervenantsDc[]>(`${this.environment.apiAdminUrl}ref/intervenant-dossier-commercial`, {
      observe: 'response',
    });
  }

  getProduit(): Observable<HttpResponse<Produit[]>> {
    return this.httpClient.get<Produit[]>(`${this.environment.apiAdminUrl}ref/produits`, {
      observe: 'response',
    });
  }

  getProduitById(id: string): Observable<Produit> {
    return this.httpClient.get<Produit>(`${this.environment.apiAdminUrl}ref/produits/` + id);
  }

  deleteIntervenatsDC(id: string): Observable<IntervenantsDc> {
    return this.httpClient.delete<IntervenantsDc>(`${this.environment.apiAdminUrl}ref/intervenant-dossier-commercial/` + id);
  }

  deleteProduit(id: string): Observable<Produit> {
    return this.httpClient.delete<Produit>(`${this.environment.apiAdminUrl}ref/produits/` + id);
  }

  getReferentielCritere(id: string): Observable<CritereReferentiel> {
    return this.httpClient.get<CritereReferentiel>(`${this.environment.apiAdminUrl}criteres/${id}`);
  }

  public saveUpdateReferentiel(referentiel: CritereReferentiel, idReferentiel: string): Observable<HttpResponse<CritereReferentiel>> {
    return this.httpClient.put<CritereReferentiel>(
      `${this.environment.apiAdminUrl}criteres/${idReferentiel}`,
      JSON.stringify(referentiel),
      {
        observe: 'response',
      }
    );
  }

  public saveUpdateReferentielDocument(
    document: ReferentielDocumentsAap,
    idRefDocument: string
  ): Observable<HttpResponse<ReferentielDocumentsAap>> {
    document.id = idRefDocument;
    return this.httpClient.put<ReferentielDocumentsAap>(
      `${this.environment.apiAdminUrl}ref/aap-documents/${idRefDocument}`,
      JSON.stringify(document),
      {
        observe: 'response',
      }
    );
  }

  public saveCritere(critere: CritereReferentiel): Observable<HttpResponse<CritereReferentiel>> {
    return this.httpClient.post<CritereReferentiel>(`${this.environment.apiAdminUrl}criteres`, JSON.stringify(critere), {
      observe: 'response',
    });
  }

  public saveCreatereferentielDocument(document: ReferentielDocumentsAap): Observable<HttpResponse<ReferentielDocumentsAap>> {
    return this.httpClient.post<ReferentielDocumentsAap>(`${this.environment.apiAdminUrl}ref/aap-documents`, JSON.stringify(document), {
      observe: 'response',
    });
  }

  public saveCreateIntervenant(intervenant: IntervenantsDc): Observable<HttpResponse<IntervenantsDc>> {
    return this.httpClient.post<IntervenantsDc>(
      `${this.environment.apiAdminUrl}ref/intervenant-dossier-commercial`,
      JSON.stringify(intervenant),
      {
        observe: 'response',
      }
    );
  }

  public saveCreateProduit(produit: Produit): Observable<HttpResponse<Produit>> {
    return this.httpClient.post<Produit>(`${this.environment.apiAdminUrl}ref/produits`, JSON.stringify(produit), {
      observe: 'response',
    });
  }

  public saveCreateDepensesProjets(depenses: DepensesProjetConfig): Observable<HttpResponse<DepensesProjetConfig>> {
    return this.httpClient.put<DepensesProjetConfig>(`${this.environment.apiAdminUrl}ref/depenses-projet`, JSON.stringify(depenses), {
      observe: 'response',
    });
  }

  getReferentielDocumentsAAP(): Observable<HttpResponse<ReferentielDocumentsAap[]>> {
    return this.httpClient.get<ReferentielDocumentsAap[]>(`${this.environment.apiAdminUrl}ref/aap-documents`, {
      observe: 'response',
    });
  }

  getReferentielDepensesProjetConfig(): Observable<HttpResponse<DepensesProjetConfig[]>> {
    return this.httpClient.get<DepensesProjetConfig[]>(`${this.environment.apiAdminUrl}ref/depenses-projet`, {
      observe: 'response',
    });
  }
  getOneReferentielDepensesProjetConfig(): Observable<DepensesProjetConfig | null> {
    if (this.depensesProjetConfig) {
      return of(this.depensesProjetConfig);
    } else {
      return this.httpClient
        .get<DepensesProjetConfig>(`${this.environment.apiAdminUrl}ref/depenses-projet`, {
          observe: 'response',
        })
        .pipe(
          map(response => response.body),
          tap(response => (this.depensesProjetConfig = response))
        );
    }
  }
}
